/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { jsx, Box, Grid, Text, Paragraph } from "theme-ui"

const Author = ({ date }) => {
  const data = useStaticQuery(graphql`
    query AuthorQuery {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author

  return (
    <>
      {author?.name && (
        <Box mb={4}>
          <Text as={Paragraph}>{author?.name}</Text>
          <Text sx={{ color: "secondaryText" }}>{date}</Text>
        </Box>
      )}
    </>
  )
}

export default Author
