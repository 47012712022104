/** @jsx jsx */
import { jsx, Flex, Heading, Box } from "theme-ui"
import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Author from "../components/author"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle} wrapper="home">
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Box>
          <Heading as="h1" variant="styles.h1">
            {post.frontmatter.title}
          </Heading>
          <Author date={post.frontmatter.date} />
        </Box>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
      <Flex sx={{ marginTop: `3rem` }}>
        {previous && (
          <Link
            to={previous.fields.slug}
            rel="prev"
            sx={{ marginRight: "auto", color: `primary` }}
          >
            ← {previous.frontmatter.title}
          </Link>
        )}
        {next && (
          <Link
            to={next.fields.slug}
            rel="next"
            sx={{ marginLeft: "auto", color: `primary` }}
          >
            {next.frontmatter.title} →
          </Link>
        )}
      </Flex>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
